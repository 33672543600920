import { types } from 'actions/types';

const authErrorAction = {
  category: 'AUTH',
  type: types.LOGOUT,
};

const status = {
  UNAUTHORIZED: 401,
};

interface DoFetchConfig {
  url: string;
  token?: string | undefined;
  options?: RequestInit;
  returnRedirect?: boolean;
  rootURL?: string;
  clientVersion?: string;
}

export const doFetch = async (
  url: string | DoFetchConfig,
  token: string | undefined = undefined,
  options: RequestInit = {},
  returnRedirect = false,
  rootURL: string = process.env.API_URL
) => {
  let json, error;

  if (typeof url === 'object') {
    // parens allow destructuring to already declared params.
    ({
      url,
      token,
      options = {},
      returnRedirect = false,
      rootURL = rootURL,
    } = url);
  }

  const fetchOptions: RequestInit = {
    ...options,
  };

  fetchOptions.headers = {
    ...fetchOptions.headers,
    'Content-Type': 'application/json',
  };

  if (token !== null) {
    fetchOptions.headers = {
      ...fetchOptions.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  try {
    const res = await fetch(rootURL + url, fetchOptions);

    if (res.status === 204) {
      return { response: {} };
    }

    const isJson = res.headers
      .get('content-type')
      ?.includes('application/json');

    json = isJson ? await res.json() : null;
    json = returnRedirect ? json : await doRedirect(json);
    if (!res.ok) {
      error = errorMessage(json);
      if (res.status === 401) {
        /*
          This will be the catch-all for the AuthErrors since
          backend returns status code 401 for all authentication related failures.
        */
        error = authErrorAction;
      } else {
        error = errorMessage(json);
      }
    }
  } catch (err) {
    error = errorMessage(err);
  }
  return { response: json, error };
};

/**
 * Used in `callFetch` method in portal api client
 */
export const callFetch = async (
  url: string | DoFetchConfig,
  token: string | undefined = undefined,
  options: RequestInit = {},
  rootURL: string = process.env.API_URL,
  returnRedirect = false
) => {
  if (typeof url === 'object') {
    // parens allow destructuring to already declared params.
    ({
      url,
      token,
      options = {},
      rootURL = rootURL,
      returnRedirect = false,
    } = url);
  }

  const fetchOptions: RequestInit = {
    ...options,
  };

  fetchOptions.headers = {
    'Content-Type': 'application/json',
  };

  if (token !== null) {
    fetchOptions.headers.Authorization = `Bearer ${token}`;
  }

  let res;
  let error;

  try {
    res = await fetch(rootURL + url, fetchOptions);
  } catch (err) {
    error = err;
  }

  if (res.status === status.UNAUTHORIZED) {
    throw new Error(`Unauthorized token`, { cause: authErrorAction });
  }

  if (error) {
    throw new Error(`Fetch Error: ${error.message}`, {
      cause: errorMessage(error),
    });
  }

  if (res.status === 204) {
    return { response: {} };
  }

  const isJson = res.headers.get('content-type')?.includes('application/json');
  let data = isJson ? await res.json() : null;
  data = returnRedirect ? data : await doRedirect(data);

  if (!res.ok) {
    const error = data && data.error ? errorMessage(data) : res.status;
    throw new Error(
      typeof error !== 'number'
        ? error.data
          ? `Error with call to ${url}`
          : res.statusText
        : 'Unknown Error',
      {
        cause: error,
      }
    );
  } else {
    return data;
  }
};

export const doRedirect = async (json) => {
  if (json && 'redirect' in json) {
    // manually perform a redirect (avoids implicit Bearer token issues with a 3xx redirect)
    return await fetch(json.redirect, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((redirect_res) => {
        if (!redirect_res.ok) {
          throw Error(
            `${redirect_res.status} - '${redirect_res.statusText}' retrieving ${redirect_res.url}`
          );
        }
        return redirect_res.json();
      })
      .catch((e) => {
        throw e;
      });
  }
  return json;
};

const errorMessage = (json) => {
  return {
    category: 'MESSAGES',
    type: types.ADD_MESSAGE,
    data: {
      message: json.error || 'Unknown Error',
      type: 'error',
      scopes: [],
      ephemeral: 5000,
    },
  };
};

interface PortalApiClientOptions {
  authToken?: string;
}

export class PortalApiClient {
  static create(options: PortalApiClientOptions) {
    return new PortalApiClient(options);
  }

  apiUrl = process.env.API_URL as string;

  clientVersion = CI_COMMIT_REF_NAME ?? 'dev';

  authToken?: string = undefined;

  constructor(options: PortalApiClientOptions = {}) {
    if (options.authToken) {
      this.authToken = options.authToken;
    }
  }

  setAuthToken(authToken?: string) {
    this.authToken = authToken;
  }

  clearAuthToken() {
    this.authToken = undefined;
  }

  fetch(
    url: string | DoFetchConfig,
    options: RequestInit = {},
    returnRedirect = false
  ) {
    const fetchOptions = {
      ...options,
      headers: {
        ...options?.headers,
        'x-leap-app-version': this.clientVersion,
      },
    };
    if (typeof url === 'object' && url.url) {
      url.token = this.authToken;
      url.options = {
        ...url.options,
        headers: {
          ...url.options?.headers,
          'x-leap-app-version': this.clientVersion,
        },
      };
    }
    return doFetch(
      url,
      this.authToken,
      fetchOptions,
      returnRedirect,
      this.apiUrl
    );
  }

  callFetch(
    url: string | DoFetchConfig,
    options: RequestInit = {},
    returnRedirect = false
  ) {
    const fetchOptions = {
      ...options,
      headers: {
        ...options?.headers,
        'x-leap-app-version': this.clientVersion,
      },
    };
    if (typeof url === 'object' && url.url) {
      url.token = this.authToken;
      url.options = {
        ...fetchOptions,
        ...url.options,
        headers: {
          ...fetchOptions.headers,
          ...url.options?.headers,
        },
      };
    }
    return callFetch(
      url,
      this.authToken,
      fetchOptions,
      this.apiUrl,
      returnRedirect
    );
  }
}
