import { IconButton as ChakraIconButton, forwardRef } from '@chakra-ui/react';
import React from 'react';
import type { IconButtonProps } from './icon-button.types';

export const IconButton = forwardRef<IconButtonProps, 'button'>(
  (props, ref) => {
    const {
      disabled,
      icon,
      iconBoxSize,
      shape = 'square',
      size,
      type,
      variant,
      ...rest
    } = props;

    return (
      <ChakraIconButton
        {...rest}
        aria-label={props['aria-label']}
        icon={React.cloneElement(icon, {
          boxSize: iconBoxSize ? iconBoxSize : 4,
        })}
        isDisabled={disabled}
        padding="0"
        ref={ref}
        shape={shape}
        size={size ? size : { base: 'sm', md: 'lg' }}
        type={type}
        variant={variant}
      />
    );
  }
);

IconButton.displayName = 'IconButton';
