import {
  MenuButton as ChakraMenuButton,
  MenuButtonProps as ChakraMenuButtonProps,
  forwardRef,
} from '@chakra-ui/react';

type MenuButtonProps = ChakraMenuButtonProps;

/**
 *  Wrapper for Chakra ui MenuButton component, [view documentation](https://chakra-ui.com/docs/components/menu). Accepts all props that the chakra component does
 */
export const MenuButton = forwardRef<MenuButtonProps, 'button'>(
  (props, ref) => {
    return <ChakraMenuButton {...props} ref={ref} />;
  }
);

MenuButton.displayName = 'MenuButton';
